import { createStore } from "vuex";
import auth from './modules/auth';
import samples from './modules/samples';
import quotes from './modules/quotes';
import salesOrders from './modules/salesOrders';
import paidCommissions from './modules/paidCommissions';
import commissionRank from './modules/commissionRank';
import trips from "./modules/trips";
import salesCalls from "./modules/sales-calls";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    samples,
    quotes,
    salesOrders,
    paidCommissions,
    commissionRank,
    trips,
    salesCalls,
  },
});
