import axios from "axios";

const state = {
    rows: [],
    details: {},
};

const getters = {
    rows: state => state.rows,
    details: state => state.details,
};

const actions = {
    async load({commit}) {
        const options = {
            params: {
                relations: ['repCompany', 'salesCalls'],
            }
        };
        return axios.get('api/trips', options).then(response => {
            commit('save', response.data.data);
        });
    },
    async loadDetails({commit}, id) {
        commit('saveDetails', {});

        const options = {
            params: {
                relations: ['repCompany', 'salesCalls', 'salesCalls.actionItems', 'salesCalls.attendees'],
            }
        };
        return axios.get(`api/trips/${id}`, options).then(response => {
            commit('saveDetails', response.data);
        });
    },
};

const mutations = {
    save(state, rows) {
        state.rows = rows;
    },
    saveDetails(state, details) {
        state.details = details;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
