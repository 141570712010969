import axios from "axios";

const state = {
    totalCompanies: 0,
    rank: 0,
};

const getters = {
    totalCompanies: state => state.totalCompanies,
    rank: state => state.rank,
};

const actions = {
    async load({ commit }) {
        return axios.get('api/rep-commissions/ranking').then(response => {
            console.log(response.data);
            commit('save', response.data);
        });
    },
};

const mutations = {
    save(state, data) {
        state.totalCompanies = data.total_companies;
        state.rank = data.rank;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
