import axios from "axios";

const state = {
    rows: [],
    details: {},
    stats: {},
};

const getters = {
    rows: state => state.rows,
    details: state => state.details,
    stats: state => state.stats,
};

const actions = {
    async load({ commit }, archived) {
        const options = {
            params: {
                relations: ['repUsers', 'comments'],
                search: { archived: archived }
            }
        };
        return axios.get('api/sample-requests', options).then(response => {
            commit('save', response.data.data);
        });
    },
    async loadDetails({ commit }, id) {
        commit('saveDetails', {});

        const options = {
            params: {
                relations: ['repUsers', 'parts', 'comments.owner'],
            }
        };
        return axios.get(`api/sample-requests/${id}`, options).then(response => {
            commit('saveDetails', response.data);
        });
    },
    async loadStats({ commit }, company_id) {
        return axios.get(`api/sample-requests/potential-value/${company_id}`).then(response => {
            commit('saveStats', response.data);
        });
    },
    async create({ commit }, data) {
        return axios.post(`api/sample-requests/`, data).then(response => {
            commit('saveDetails', response.data);
        });
    },
    async addComment({ commit, state }, text) {
        const params = {
            sample_request_id: state.details.id,
            text: text
        };
        return axios.post(`api/comments/`, params).then(response => {
            commit('saveComment', response.data);
        });
    },
    async updateSalesStatus({ commit, state }, status) {
        const params = {
            sales_status: status,
        };
        return axios.patch(`api/sample-requests/${state.details.id}/sales-status`, params).then(response => {
            let sales_status = response.data.sales_status;
            let sales_status_text = response.data.sales_status_text;
            commit('saveSalesStatus', { sales_status, sales_status_text });
        });
    }
};

const mutations = {
    save(state, rows) {
        state.rows = rows;
    },
    saveDetails(state, details) {
        state.details = details;
    },
    saveStats(state, stats) {
        state.stats = stats;
    },
    saveComment(state, comment) {
        state.details.comments.push(comment);
    },
    saveSalesStatus(state, { sales_status, sales_status_text }) {
        state.details.sales_status = sales_status;
        state.details.sales_status_text = sales_status_text;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
