import axios from "axios";

const state = {
    rows: [],
    details: {},
};

const getters = {
    rows: state => state.rows,
    details: state => state.details,
};

const actions = {
    async load({ commit }) {
        return axios.get('api/rep-commissions/paid-commissions').then(response => {
            commit('save', response.data.data);
        });
    },
    async loadDetails({ commit }, id) {
        commit('saveDetails', {});

        return axios.get(`api/rep-commissions/paid-commissions/${id}`).then(response => {
            commit('saveDetails', response.data);
        });
    },
};

const mutations = {
    save(state, rows) {
        state.rows = rows;
    },
    saveDetails(state, details) {
        state.details = details;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
