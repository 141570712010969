import axios from "axios";
const state = {
  user: JSON.parse(localStorage.getItem("user")),
  tempUser: {},
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  user: (state) => state.user,
  tempUser: (state) => state.tempUser,
};

const actions = {
  async LogIn({ commit }, User) {
    await axios.get("sanctum/csrf-cookie");
    await axios.post("rep/login", User).then((response) => {
      commit("setUser", response.data);
      localStorage.setItem("user", JSON.stringify(response.data));
    });
  },

  async LogOut() {
    await axios.get("logout");
    localStorage.removeItem("user");
    window.location.href = "/login";
  },

  async Unauthorized($route) {
    await axios.get("logout");
    localStorage.removeItem("user");
    window.location.href = `/login?referrer=${$route.path}`;
  },

  async Register({ commit }, formData) {
    await axios.get("sanctum/csrf-cookie");
    await axios
      .post(`rep/register/${formData.token}`, formData)
      .then((response) => {
        commit("setUser", response.data);
        localStorage.setItem("user", JSON.stringify(response.data));
      });
  },

  async ForgotPassword(_, formData) {
    await axios.get("sanctum/csrf-cookie");
    await axios.post(`api/rep-center/forgot-password`, formData);
  },

  async ChangePassword(_, formData) {
    await axios.get("sanctum/csrf-cookie");
    await axios.post(`api/rep-center/change-password`, formData);
  },

  async GetUserIdByToken({ commit }, token) {
    await axios.get("sanctum/csrf-cookie");
    await axios
      .post(`rep/id-by-token/${token}`, {}, { validateStatus: false })
      .then((response) => {
        commit("setTempUserId", response.data);
      });
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setTempUserId(state, userId) {
    state.tempUser.id = userId;
  },
  LogOut(state) {
    state.user = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
