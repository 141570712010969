import { createRouter, createWebHistory } from "vue-router";


const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: () =>
      import("../views/Dashboard.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import("../views/Login.vue"),
  }, {
    path: "/samples/create",
    name: "Create Sample",
    component: () =>
      import("../views/CreateSample.vue"),
  },
  {
    path: "/samples/:id",
    name: "Sample",
    component: () =>
      import("../views/Sample.vue"),
  },
  {
    path: "/samples",
    name: "Samples",
    component: () =>
      import("../views/Samples.vue"),
  }, {
    path: "/quotes/create",
    name: "Create Quote",
    component: () =>
      import("../views/CreateQuote.vue"),
  }, {
    path: "/quotes/:id",
    name: "Quote",
    component: () =>
      import("../views/Quote.vue"),
  }, {
    path: "/quotes",
    name: "Quotes",
    component: () =>
      import("../views/Quotes.vue"),
  }, {
    path: "/commissions",
    name: "Commissions",
    component: () =>
      import("../views/Commissions.vue"),
  }, {
    path: "/commissions/sales-orders/:id",
    name: "Sales order",
    component: () =>
      import("../views/SalesOrder.vue"),
  }, {
    path: "/commissions/paid-commissions/:id",
    name: "Paid Commission",
    component: () =>
      import("../views/PaidCommission.vue"),
  }, {
    path: "/trips",
    name: "Trips",
    component: () =>
      import("../views/Trips.vue"),
  }, {
    path: "/trips/:id",
    name: "Trip",
    component: () =>
      import("../views/Trip.vue"),
  }, {
    path: "/sales-calls/:id",
    name: "Sales Call",
    component: () =>
      import("../views/SalesCall.vue"),
  }, {
    path: "/forgot-password",
    name: "Forgot Password",
    component: () =>
      import("../views/ForgotPassword.vue"),
  }, {
    path: "/change-password/:token",
    name: "Change Password",
    component: () =>
      import("../views/ChangePassword.vue"),
  }, {
    path: "/register/:token",
    name: "Register",
    component: () =>
      import("../views/Register.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/forgot-password', '/change-password'];
  let authRequired = true;
  for (var i = 0; i < publicPages.length; i++) {
    if (to.path.startsWith(publicPages[i])) {
      authRequired = false;
      break;
    }
  }
  const isLoggedIn = localStorage.getItem('user');

  if (authRequired && !isLoggedIn) {
    localStorage.setItem('desired_path', to.path)
    return next(`/login?referrer=${to.path}`);
  }

  next();
});

export default router;
