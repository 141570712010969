import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/tailwind.css";
import axios from 'axios';
import VueFinalModal from 'vue-final-modal'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const UNAUTHORIZED = 401;
axios.interceptors.response.use(
    response => response,
    error => {
        const {status} = error.response;
        if (status === UNAUTHORIZED) {
            store.dispatch('Unauthorized');
        }
        return Promise.reject(error);
    }
);

createApp(App).use(VueFinalModal()).use(store).use(router).mount("#app");
