import axios from "axios";

const state = {
    rows: [],
    details: {},
};

const getters = {
    rows: state => state.rows,
    details: state => state.details,
};

const actions = {
    async load({ commit }, archived) {
        const options = {
            params: {
                relations: ['repUsers', 'comments', 'customer'],
                search: { archived: archived }
            }
        };
        return axios.get('api/quotes', options).then(response => {
            commit('save', response.data.data);
        });
    },
    async loadDetails({ commit }, id) {
        commit('saveDetails', {});

        const options = {
            params: {
                relations: ['repUsers', 'comments', 'customer', 'comments.owner', 'user', 'parts.prices'],
            }
        };
        return axios.get(`api/quotes/${id}`, options).then(response => {
            commit('saveDetails', response.data);
        });
    },
    async create(_, data) {
        return axios.post(`api/quotes/`, data);
    },
    async addComment({ commit, state }, text) {
        const params = {
            quote_id: state.details.id,
            text: text
        };
        return axios.post(`api/comments/`, params).then(response => {
            commit('saveComment', response.data);
        });
    },
    async updateStatus({ commit, state }, status) {
        const params = {
            status: status,
        };
        return axios.patch(`api/quotes/${state.details.id}/status`, params).then(response => {
            let status = response.data.sales_status;
            let status_text = response.data.status_text;
            commit('saveStatus', { status, status_text });
        });
    }
};

const mutations = {
    save(state, rows) {
        state.rows = rows;
    },
    saveDetails(state, details) {
        state.details = details;
    },
    saveComment(state, comment) {
        state.details.comments.push(comment);
    },
    saveStatus(state, { status, status_text }) {
        state.details.status = status;
        state.details.status_text = status_text;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
