import axios from "axios";

const state = {
  rows: [],
  details: {},
};

const getters = {
  rows: (state) => state.rows,
  details: (state) => state.details,
};

const actions = {
  async loadDetails({ commit }, id) {
    commit("saveDetails", {});

    const options = {
      params: {
        relations: [
          "trip",
          "attendees",
          "actionItems",
          "comments",
          "comments.owner",
          "actionItems.responsible",
        ],
      },
    };
    return axios.get(`api/sales-calls/${id}`, options).then((response) => {
      commit("saveDetails", response.data);
    });
  },
  async addComment({ commit, state }, text) {
    const params = {
      sales_call_id: state.details.id,
      text: text,
    };
    return axios.post(`api/comments/`, params).then((response) => {
      commit("saveComment", response.data);
    });
  },
};

const mutations = {
  saveDetails(state, details) {
    state.details = details;
  },
  saveComment(state, comment) {
    state.details.comments.push(comment);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
